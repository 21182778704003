
import { 
  IonPage, 
  IonContent, 
  IonCol, 
  IonGrid, 
  IonRow, 
  IonLoading, 
  IonRefresher, 
  IonRefresherContent 
} from '@ionic/vue';

import Header from '@/components/Header.vue';

// @ts-ignore
import filterHelperMethods from '@/helper/filter'
// @ts-ignore
import viewHelperMethods from '@/helper/view'

// @ts-ignore
import * as api from '@/service/index.js'

import { Plugins } from '@capacitor/core';

export default  {
  name: 'Blog',
  components: { 
    Header, 
    IonContent, 
    IonPage, 
    IonCol, 
    IonGrid, 
    IonRow,
    IonLoading, 
    IonRefresher, 
    IonRefresherContent
  },
  data () {
    return {
      start: 1,
      isLoading: false,
      totalEntries: 0,
      entries: [],
      showTicker: false,
      lastScrollLoad: 0,
      firstLoadDone: false,
      inAppBrowserOpen: false
    }
  },
  computed: {
    showLoadMore (): boolean {
      return (this as any).totalEntries > (this as any).entries.length
    },
    showNoEntries (): boolean {
      return !(this as any).isLoading && (this as any).firstLoadDone && !(this as any).entries.length 
    }
  },
  methods: {
    ...filterHelperMethods,
    ...viewHelperMethods,
    onScroll (event: any) {
      const vm = (this as any);
      let shouldLoad = vm.shouldLoadMoreBasedOnScrollPosition();
      if (shouldLoad && !vm.isLoading && 
        event.detail.scrollTop > (vm.lastScrollLoad + (document.body.clientHeight / 2))
      ) {
          vm.lastScrollLoad = event.detail.scrollTop;
          console.log('loading new content due to scroll position');
          vm.loadMore(null, true);
        }
    },
    reset () {
      (this as any).start = 1;
      (this as any).isLoading = false;
      (this as any).totalEntries = 0;
      (this as any).entries = []; 
      (this as any).showTicker = false; 
      (this as any).lastScrollLoad = 0;
      (this as any).firstLoadDone = false;
      (this as any).inAppBrowserOpen = false;
    },
    setLoading (val: boolean) {
      console.log('set loading %s', val);
      (this as any).isLoading = val;
    },
    async loadMore (event: any, loadOnClick: boolean) {
      console.log('start load');
      const vm = (this as any);

      if (loadOnClick) {
        console.log('loading new content due to user interaction');
      } else {
        vm.setLoading(true);
      }
      if (!vm.firstLoadDone) {
        try {
          console.log('loading ticker data')
          const tickerData = await api.getBlogTicker();
          console.log(tickerData);

          if (tickerData.status === 200 && tickerData.data.ticker?.enabled) {
            vm.tickerText = tickerData.data.ticker.text
            vm.tickerColor = tickerData.data.ticker.color
            vm.showTicker = true
          }
        } catch (error) {
          vm.handleAxiosError(error);
          console.log(error);
        }
      }

      try {
        const data = await api.getBlogFeed(vm.start);
        console.log(data);

        if (data.data.entries.length) {
          for (let i in data.data.entries) {
            vm.entries.push(data.data.entries[i]);        
          }
          vm.totalEntries = data.data.totalEntries;
          vm.start += 1;
        }
      } catch (error) {
        vm.handleAxiosError(error);
        console.log(error);
      }

      if ((window as any).twttr) {
        console.log('init twitter widgets');
        (window as any).twttr.widgets.load();
      }

      console.log('end load');
      console.log(vm.entries);
      vm.setLoading(false);
      vm.firstLoadDone = true;

      if (event?.type === 'ionrefresh') {
        event.target.complete();
      }
    },
    async doRefresh (event: any) {
      if (!(this as any).isLoading) {
        (this as any).reset();
        (this as any).loadMore(event);
      }
    },
    handleAxiosError (err: any) {
      if (err && err.message) {
        if (err.message.indexOf('401') !== -1) {
          console.log('unauthorized');
          localStorage.removeItem('jwt');
          localStorage.removeItem('jwt_expires');
          (this as any).$router.push('/auth');
        }
      }
    },
    async openIntensitaetsstufenPdf () {
      (this as any).inAppBrowserOpen = true;
      const { Browser } = Plugins;
      await Browser.open({ url: 'https://ukk-aktuell-app.uk-koeln.de/assets/pdf/20210716-intensitaetsstufen-uniklinik-koeln.pdf' });
    },
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave blog feed');
    (this as any).reset();
  },
  ionViewDidEnter () {
    console.log('[ionView] entered blog feed');
    (this as any).doRefresh();
  },
  mounted () {
    const vm = (this as any);
    vm.doRefresh();

    vm.$bus.on('appStateChanged', (isActive: boolean) => {
      console.log('caught global app state change event', isActive);
      if (isActive && !vm.inAppBrowserOpen) {
        // vm.scrollTop();
        vm.doRefresh();
      }
    });
  }
}
