import { render } from "./Feed.vue?vue&type=template&id=00bdf8cb&scoped=true"
import script from "./Feed.vue?vue&type=script&lang=ts"
export * from "./Feed.vue?vue&type=script&lang=ts"

import "./Feed.vue?vue&type=style&index=0&id=00bdf8cb&lang=css"
import "./Feed.vue?vue&type=style&index=1&id=00bdf8cb&scoped=true&lang=css"
import "./Feed.vue?vue&type=style&index=2&id=00bdf8cb&lang=less"
import "./Feed.vue?vue&type=style&index=3&id=00bdf8cb&scoped=true&lang=less"
script.render = render
script.__scopeId = "data-v-00bdf8cb"

export default script