<template>
  <ion-page>
    <Header name="Blog" />
    <ion-content 
      :fullscreen="true"
      :scroll-events="true"
      @ionScroll="onScroll($event)"
    >
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div id="container">

        <ion-loading
          :is-open="isLoading"
          message="Lade Daten.."
          @onDidDismiss="setLoading(false)"
          :duration="3000"
        >
        </ion-loading>

        <div class="blog-header" :style="{ backgroundImage: 'url(' + require('@/assets/img/blog-header.png') + ')' }">
          <h1 class="font-face--merrisans light">Newsblog zur Corona-Pandemie</h1>
        </div>

        <ion-grid style="padding: 0">

          <ion-row v-if="showTicker" class="ticker">
            <ion-col>
              <div 
                class="card" 
                :style="{'background-color': tickerColor}"
                v-html="tickerText"
              />
            </ion-col>
          </ion-row>

          <ion-row v-if="showNoEntries" class="no-data">
            <ion-col>
              <div class="card">
                <h3>Keine Einträge</h3>
              </div>
            </ion-col>
          </ion-row>

          <div v-else>
            <ion-row 
              v-for="(entry, index) in entries" 
              :key="index"
            >
              <ion-col style="padding: 0">
                <div class="card news-entry blog-entry">
                  <div class="content">
                    <div class="text">
                      <h3 class="font-face--merrisans light">{{ entry.title }}</h3>
                      <time class="font-face--merrisans light">{{ getFormattedDate(entry.datetime, true) }}</time>
                      <p class="font-face--merrisans light" v-html="getHTML(entry.teaser, !$cc.getUserPreferences().accepted_categories.includes('functional'))"></p>
                    </div>
                  </div>
                  <div class="meta font-face--merrisans light">
                    <!-- <time>{{ getFormattedDate(entry.datetime) }}</time> -->
                  </div>
                </div>
              </ion-col>
            </ion-row>

            <ion-row 
              v-if="showLoadMore" 
              class="load-more"
              :class="{'invisible': isLoading}"
            >
              <ion-col>
                <a href="#" class="btn btn-next btn-secondary" role="button" @click.prevent="loadMore(null, true)">
                  <span>Mehr laden</span>
                </a>
              </ion-col>
            </ion-row>
          </div>

        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { 
  IonPage, 
  IonContent, 
  IonCol, 
  IonGrid, 
  IonRow, 
  IonLoading, 
  IonRefresher, 
  IonRefresherContent 
} from '@ionic/vue';

import Header from '@/components/Header.vue';

// @ts-ignore
import filterHelperMethods from '@/helper/filter'
// @ts-ignore
import viewHelperMethods from '@/helper/view'

// @ts-ignore
import * as api from '@/service/index.js'

import { Plugins } from '@capacitor/core';

export default  {
  name: 'Blog',
  components: { 
    Header, 
    IonContent, 
    IonPage, 
    IonCol, 
    IonGrid, 
    IonRow,
    IonLoading, 
    IonRefresher, 
    IonRefresherContent
  },
  data () {
    return {
      start: 1,
      isLoading: false,
      totalEntries: 0,
      entries: [],
      showTicker: false,
      lastScrollLoad: 0,
      firstLoadDone: false,
      inAppBrowserOpen: false
    }
  },
  computed: {
    showLoadMore (): boolean {
      return (this as any).totalEntries > (this as any).entries.length
    },
    showNoEntries (): boolean {
      return !(this as any).isLoading && (this as any).firstLoadDone && !(this as any).entries.length 
    }
  },
  methods: {
    ...filterHelperMethods,
    ...viewHelperMethods,
    onScroll (event: any) {
      const vm = (this as any);
      let shouldLoad = vm.shouldLoadMoreBasedOnScrollPosition();
      if (shouldLoad && !vm.isLoading && 
        event.detail.scrollTop > (vm.lastScrollLoad + (document.body.clientHeight / 2))
      ) {
          vm.lastScrollLoad = event.detail.scrollTop;
          console.log('loading new content due to scroll position');
          vm.loadMore(null, true);
        }
    },
    reset () {
      (this as any).start = 1;
      (this as any).isLoading = false;
      (this as any).totalEntries = 0;
      (this as any).entries = []; 
      (this as any).showTicker = false; 
      (this as any).lastScrollLoad = 0;
      (this as any).firstLoadDone = false;
      (this as any).inAppBrowserOpen = false;
    },
    setLoading (val: boolean) {
      console.log('set loading %s', val);
      (this as any).isLoading = val;
    },
    async loadMore (event: any, loadOnClick: boolean) {
      console.log('start load');
      const vm = (this as any);

      if (loadOnClick) {
        console.log('loading new content due to user interaction');
      } else {
        vm.setLoading(true);
      }
      if (!vm.firstLoadDone) {
        try {
          console.log('loading ticker data')
          const tickerData = await api.getBlogTicker();
          console.log(tickerData);

          if (tickerData.status === 200 && tickerData.data.ticker?.enabled) {
            vm.tickerText = tickerData.data.ticker.text
            vm.tickerColor = tickerData.data.ticker.color
            vm.showTicker = true
          }
        } catch (error) {
          vm.handleAxiosError(error);
          console.log(error);
        }
      }

      try {
        const data = await api.getBlogFeed(vm.start);
        console.log(data);

        if (data.data.entries.length) {
          for (let i in data.data.entries) {
            vm.entries.push(data.data.entries[i]);        
          }
          vm.totalEntries = data.data.totalEntries;
          vm.start += 1;
        }
      } catch (error) {
        vm.handleAxiosError(error);
        console.log(error);
      }

      if ((window as any).twttr) {
        console.log('init twitter widgets');
        (window as any).twttr.widgets.load();
      }

      console.log('end load');
      console.log(vm.entries);
      vm.setLoading(false);
      vm.firstLoadDone = true;

      if (event?.type === 'ionrefresh') {
        event.target.complete();
      }
    },
    async doRefresh (event: any) {
      if (!(this as any).isLoading) {
        (this as any).reset();
        (this as any).loadMore(event);
      }
    },
    handleAxiosError (err: any) {
      if (err && err.message) {
        if (err.message.indexOf('401') !== -1) {
          console.log('unauthorized');
          localStorage.removeItem('jwt');
          localStorage.removeItem('jwt_expires');
          (this as any).$router.push('/auth');
        }
      }
    },
    async openIntensitaetsstufenPdf () {
      (this as any).inAppBrowserOpen = true;
      const { Browser } = Plugins;
      await Browser.open({ url: 'https://ukk-aktuell-app.uk-koeln.de/assets/pdf/20210716-intensitaetsstufen-uniklinik-koeln.pdf' });
    },
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave blog feed');
    (this as any).reset();
  },
  ionViewDidEnter () {
    console.log('[ionView] entered blog feed');
    (this as any).doRefresh();
  },
  mounted () {
    const vm = (this as any);
    vm.doRefresh();

    vm.$bus.on('appStateChanged', (isActive: boolean) => {
      console.log('caught global app state change event', isActive);
      if (isActive && !vm.inAppBrowserOpen) {
        // vm.scrollTop();
        vm.doRefresh();
      }
    });
  }
}
</script>

<style>
.error p {
    background: lightcoral;
    border-radius: 3px;
    padding: 15px;
    margin: 5px 17px;
    color: #fff;
    font-weight: bold;
}

.error p a {
  color: #fff;
}
</style>
<style scoped>
.ticker .card:deep(p) {
  margin-block-start: 0;
  margin-block-end: 0;
}

.ticker .card:deep(a) {
  color: #fff;
}
</style>

<style lang="less">
.twitter-tweet {
  position: relative;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
  }  
}
</style>

<style scoped lang="less">
.ticker {
  .card {
    color: #fff;
    // margin: 5px 0;
  }
}

.load-more {
  margin-top: 15px;
}

.blog-header {
  height: 130px;
  width: 100%;
  margin-top: -20px;
  padding: 20px 30px;
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    color: #fff;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    max-width: 250px;
  }
}

.invisible {
  opacity: 0;
}

.hidden {
  display: none;
}

.blog-entry {
  h3 {
    font-size: 18px !important;
    line-height: 28px !important;
    color: var(--text-color-grey);
    margin-bottom: 5px;
    margin-top: 3px !important;
  }

  time {
    font-size: 12px !important;
    line-height: 20px !important;
    color: var(--text-color-lightgrey);
    margin-bottom: 15px;
  }

  .meta {
    padding: 0 !important;
    margin: 0 15px !important;
  }
}

@media (prefers-color-scheme: dark) {
  .card {
    background: var(--background-black);

    h3 {
      color: #fff;
    }

    p {
      color: #fff;
    }
  }

  .no-data .card {
    background: var(--ion-card-background);

    h3 {
      color: var(--background-lightblue);
    }
  }
}

body.dark {
  .card {
    background: var(--background-black);

    h3 {
      color: #fff;
    }

    p {
      color: #fff;
    }
  }

  .no-data .card {
    background: var(--ion-card-background);

    h3 {
      color: var(--background-lightblue);
    }
  }
}
</style>